import { Box, IconButton } from "@modernatx/ui-kit-react";

import { useNavPanel } from "../../hooks/useNavPanel";

const NavButton = () => {
  const { navPanelShow } = useNavPanel();

  return (
    <Box
      sx={{
        display: ["flex", "flex", "none"]
      }}
    >
      <IconButton aria-label="Show navigation" size="lg" icon="list" onClick={navPanelShow} />
    </Box>
  );
};

export default NavButton;
