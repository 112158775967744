import { Box, DEFAULT_ICONS, Grid, GridColumn, Icon, Link, Text } from "@modernatx/ui-kit-react";
import React from "react";

import { ContactOptionsProps } from "@/types/Block";
import { BlockTextProps } from "@/types/BlockText";

import { BlockText } from "../BlockText";

export interface ContactCard {
  icon?: keyof typeof DEFAULT_ICONS;
  title?: string;
  subtitle?: string;
  websiteLinkHref?: string;
  websiteLinkText?: string;
  websiteLinkExternal?: boolean;
  subtitleBlockText?: BlockTextProps[];
}

export const ContactOptions: React.FC<ContactOptionsProps> = (props: ContactOptionsProps) => {
  const { title, subtitle, contactOptions } = props;

  return (
    <Grid data-nosnippet="true">
      <GridColumn>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            pb: [5, 5, 10],
            textAlign: "center"
          }}
        >
          <Text as="h2" variant="bold" size="3xl">
            {title}
          </Text>
          <Text as="p" sx={{ pt: 5 }} size="xl">
            {subtitle}
          </Text>
        </Box>
      </GridColumn>
      <GridColumn>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            alignItems: "center",
            justifyContent: "space-evenly"
          }}
        >
          {contactOptions?.map((item, i) => (
            <GridColumn key={i} size={[4, 8, 4]}>
              <Box
                sx={{
                  alignItems: "center",
                  borderRadius: "medium",
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  mt: [i === 0 ? 0 : 8, null, 0],
                  textAlign: "center"
                }}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex"
                  }}
                >
                  <Icon color="accent01" icon={item.icon || "globe"} size="xl" />
                </Box>
                <Text as="p" size="xl" variant="medium" sx={{ color: "text01", py: 2 }}>
                  {item.title}
                </Text>
                {subtitle && (
                  <Text as="p" sx={{ color: "text02", whiteSpace: "pre-wrap" }}>
                    {item.subtitle}
                  </Text>
                )}
                {item.subtitleBlockText && (
                  <Box sx={{ color: "text02", whiteSpace: "pre-wrap" }}>
                    {item.subtitleBlockText.map((textNode, index) => (
                      <BlockText key={index} {...textNode} />
                    ))}
                  </Box>
                )}
                {item.websiteLinkHref && item.websiteLinkText && (
                  <Link
                    href={item.websiteLinkHref}
                    sx={{ alignItems: "center", display: "flex" }}
                    target={item.websiteLinkExternal ? "_blank" : undefined}
                    rel={item.websiteLinkExternal ? "noopener" : undefined}
                  >
                    {item.websiteLinkText}
                  </Link>
                )}
              </Box>
            </GridColumn>
          ))}
        </Box>
      </GridColumn>
    </Grid>
  );
};
