import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { Language } from "@/types/Language";

export const useAvailableLanguages = () => {
  const { alternates = [], language, environment, country: countryCode } = useExperience();

  const availableLanguages = React.useMemo(() => {
    const languagesSet = new Set<Language>();

    alternates.forEach((alt) => {
      const showAlternate = environment === "production" ? alt.isLiveInProduction : true;

      if (
        showAlternate &&
        alt.language &&
        alt.language !== language &&
        alt.country === countryCode
      ) {
        languagesSet.add(alt.language);
      }
    });

    return languagesSet;
  }, [alternates, language, environment, countryCode]);

  return availableLanguages;
};
