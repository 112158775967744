import { Box, Button, IconButton } from "@modernatx/ui-kit-react";
import { usePathname } from "next/navigation";
import React, { ReactNode } from "react";

import { ButtonBlockProps, NavLinkProps } from "@/types/Block";

import { useNavPanel } from "../../hooks/useNavPanel";
import { BlockText } from "../BlockText";
import { useRsvContent } from "../rsv/hooks/useRsvContent";
import { NavArrow } from "./NavArrow";
import { NavGroupItemProps } from "./NavGroupItem";
import { NavIntersector } from "./NavIntersector";
import { NavLink } from "./NavLink";

interface NavGroupProps {
  button?: ButtonBlockProps;
  children: ReactNode;
  links?: NavLinkProps[];
}

const NavGroup: React.FC<NavGroupProps> = ({ button, children, links }) => {
  const id = `c${React.useId().replace(/[^A-Z0-9]/gi, "")}`;
  const intersectionMap = React.useRef<number[]>([]);
  const navRef = React.useRef<HTMLDivElement | null>(null);
  const navTopRef = React.useRef<HTMLDivElement | null>(null);
  const navListRef = React.useRef<HTMLUListElement | null>(null);
  const [activeItem, setActiveItem] = React.useState<number>(-1);
  const [isAtStart, setIsAtStart] = React.useState<boolean>(true);
  const [isAtEnd, setIsAtEnd] = React.useState<boolean>(false);
  const [isSticky, setIsSticky] = React.useState<boolean>(false);
  const { navPanelShow } = useNavPanel();
  const { healthcareProviderUrl } = useRsvContent();
  const pathname = usePathname();

  React.useEffect(() => {
    const checkIfSticky = () => {
      const navTop = navTopRef.current;
      if (navTop) {
        const rect = navTop.getBoundingClientRect();
        setIsSticky(Math.floor(rect.top) <= 0);
      }
    };

    checkIfSticky();

    window.addEventListener("scroll", checkIfSticky);
    return () => window.removeEventListener("scroll", checkIfSticky);
  }, [navTopRef]);

  const checkScrollPosition = React.useCallback(() => {
    if (!navListRef.current) {
      return;
    }
    setIsAtStart(navListRef.current.scrollLeft <= 1);
    setIsAtEnd(
      navListRef.current.scrollLeft + navListRef.current.offsetWidth + 1 >=
        navListRef.current.scrollWidth
    );
  }, []);

  const handleIntersecting = React.useCallback(
    (index: number, intersecting: boolean) => {
      if (intersecting && intersectionMap.current.indexOf(index) === -1) {
        intersectionMap.current.push(index);
        intersectionMap.current.sort(undefined);
      } else if (!intersecting && intersectionMap.current.indexOf(index) !== -1) {
        intersectionMap.current = intersectionMap.current.filter((i) => i !== index);
        intersectionMap.current.sort(undefined);
      }

      const activeItemNext =
        intersectionMap.current[0] !== undefined ? intersectionMap.current[0] : -1;

      if (activeItem !== activeItemNext) {
        setActiveItem(activeItemNext);
      }
    },
    [activeItem]
  );

  const scrollNav = React.useCallback((direction: "left" | "right") => {
    if (navListRef.current) {
      const currentScroll = navListRef.current.scrollLeft;
      const scrollAmount =
        direction === "left" ? -navListRef.current.offsetWidth : navListRef.current.offsetWidth;
      navListRef.current.scrollTo({
        left: currentScroll + scrollAmount,
        behavior: "smooth"
      });
    }
  }, []);

  React.useEffect(() => {
    checkScrollPosition();

    const handleResize = () => {
      checkScrollPosition();
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [checkScrollPosition]);

  const handleNavLinkClick = React.useCallback((index: number, event: React.MouseEvent) => {
    event.preventDefault();
    const sectionElement = document.querySelector(`[data-scroll-element="${index}"]`);
    const navLink = document.querySelector(`[data-nav-link="${index}"]`);

    if (sectionElement) {
      // We need to account for the navigation bar height so that it doesn't sit on top of the content
      const navHeightOffset = index === 0 ? 36 : 72;
      const sectionTop =
        sectionElement.getBoundingClientRect().top + window.scrollY - navHeightOffset;

      window.scrollTo({
        top: sectionTop,
        behavior: "smooth"
      });

      if (navLink && navListRef.current) {
        const navLinkPosition =
          navLink.getBoundingClientRect().left - navListRef.current.getBoundingClientRect().left;

        const scrollAmount =
          navLinkPosition +
          navLink.getBoundingClientRect().width / 2 -
          navListRef.current.offsetWidth / 2;

        navListRef.current.scroll({
          left: navListRef.current.scrollLeft + scrollAmount,
          behavior: "smooth"
        });
      }
    }
  }, []);

  const navigationLinks = links
    ? links.map((link, index) => (
        <Box
          key={index}
          as="li"
          sx={{
            alignItems: "center",
            display: "flex",
            flexShrink: 0
          }}
        >
          <NavLink active={pathname === link.href} data-scroll={id} index={index} href={link.href}>
            {link.text.map((text, i) => (
              <BlockText key={i} text={text} />
            ))}
          </NavLink>
        </Box>
      ))
    : React.Children.map(children, (child, index) => {
        if (React.isValidElement<NavGroupItemProps>(child) && child.props.navButtonLabel) {
          return (
            <Box
              key={index}
              as="li"
              sx={{
                alignItems: "center",
                display: "flex",
                flexShrink: 0
              }}
            >
              <NavLink
                active={isSticky && activeItem === index}
                index={index}
                onClick={(event) => handleNavLinkClick(index, event)}
              >
                {child.props.navButtonLabel}
              </NavLink>
            </Box>
          );
        }
        return null;
      });

  return (
    <>
      <Box ref={navTopRef} id={id} sx={{ top: 0, position: "relative" }} />
      <Box
        as="nav"
        sx={{
          backgroundColor: "backgroundNeutral01",
          borderRadius: isSticky ? 0 : [0, 0, "large"],
          boxShadow: "boxShadow01",
          display: "flex",
          flexDirection: "column",
          maxWidth: ["100%", "100%", isSticky ? "100%" : "fit-content"],
          mb: -9,
          mt: -9,
          mx: "auto",
          overflow: "hidden",
          position: "sticky",
          top: 0,
          zIndex: "navbar"
        }}
        title="Secondary Navigation"
      >
        <Box
          ref={navRef}
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          {button && isSticky && (
            <Box
              sx={{
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: "stroke02",
                display: ["flex", "flex", "none"],
                px: [5, 8, 8],
                py: 3,
                // @ts-ignore
                "> *": {
                  flex: 1
                }
              }}
            >
              <Button href={button.href}>{button.title}</Button>
            </Box>
          )}
          <Box sx={{ position: "relative", flex: 1, px: [5, 8, 8] }}>
            {!isAtStart && (
              <NavArrow aria-label="Previous" direction="start" onClick={() => scrollNav("left")} />
            )}
            {!isAtEnd && (
              <NavArrow aria-label="Next" direction="end" onClick={() => scrollNav("right")} />
            )}
            <Box
              ref={navListRef}
              as="ul"
              sx={{
                alignItems: "center",
                display: "flex",
                flexGrow: 1,
                gap: [4, 7, isSticky ? 7 : 14],
                justifyContent: isSticky
                  ? ["start", "space-evenly", "flex-start"]
                  : ["start", "space-evenly", "center"],
                listStyleType: "none",
                msOverflowStyle: "none",
                overflowX: "auto",
                scrollbarWidth: "none",
                // @ts-ignore the following psuedo isn't available in the UI Kit
                "::-webkit-scrollbar": {
                  display: "none"
                }
              }}
              tabIndex={0}
              onScroll={checkScrollPosition}
            >
              {navigationLinks}
              {isSticky && (
                <Box
                  as="li"
                  sx={{
                    display: ["none", "none", "block"],
                    marginInlineStart: "auto"
                  }}
                >
                  {button && (
                    <Box
                      as="div"
                      sx={{
                        display: ["none", "none", "block"],
                        marginInlineStart: "auto"
                      }}
                    >
                      <Button href={button.href} sx={{ flexGrow: 0 }}>
                        {button.title}
                      </Button>
                    </Box>
                  )}
                  {healthcareProviderUrl && (
                    <IconButton
                      aria-label="Show navigation"
                      size="lg"
                      icon="list"
                      variant="transparent"
                      onClick={navPanelShow}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <NavIntersector
        index={-1}
        onIntersecting={(intersecting) => handleIntersecting(-1, intersecting)}
      />
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement<NavGroupItemProps>(child)) {
          return (
            <NavIntersector
              key={index}
              index={index}
              onIntersecting={(intersecting) => handleIntersecting(index, intersecting)}
            >
              {child}
            </NavIntersector>
          );
        }
        return null;
      })}
    </>
  );
};

export default NavGroup;
